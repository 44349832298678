import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ translations }) => {

    const objectTranslations = translations?.components?.header?.translations || {};

    const bannerTitleText = objectTranslations['header.banner.title'];
    const bannerTBodyText = objectTranslations['header.banner.body'];
    const bannerButtonText = objectTranslations['header.banner.button'];
    const bannerButtonUrl = objectTranslations['header.banner.button.url'];

    return (
        <header id="head">
            <div className="container">
                <div className="row">
                    <h1 className="lead">{ bannerTitleText }</h1>
                    <p className="tagline">
                        { bannerTBodyText }                 
                    </p>
                    <p>
                        <Link className="btn btn-default btn-lg" role="button" to={ bannerButtonUrl }>
                            { bannerButtonText }
                        </Link>  
                    </p>
                </div>
            </div>
        </header>
    );
};

export default Header;
