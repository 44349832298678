import React from 'react';
import { Link } from 'react-router-dom';

const SignIn = ({ translations }) => {
    const navbarTranslations = translations?.components?.navbar?.translations || {};
    const homeTitleText = navbarTranslations['menu.title.home'];
    const signInTitleText = navbarTranslations['menu.title.signin'];

    const objectTranslations = translations?.components?.signin?.translations || {};
    const signinTitleText = objectTranslations['signin.title'];
    const signinFieldUsernameText = objectTranslations['signin.field.username'];
    const signinFieldPasswordText = objectTranslations['signin.field.password'];
    const signinButtonSendText = objectTranslations['signin.button.send'];

    return (
        <>
            <header id="head" className="secondary"></header>

            {/* container */}
            <div className="container">

                <ol className="breadcrumb">
                    <li><Link to="/">{ homeTitleText }</Link></li>
                    <li className="active">{ signInTitleText }</li>
                </ol>

                <div className="row">
                    
                    {/* Article main content */}
                    <article className="col-xs-12 maincontent">
                        <header className="page-header">
                            <h1 className="page-title">{ signInTitleText }</h1>
                        </header>
                        
                        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <h3 className="thin text-center">{ signinTitleText }</h3>
                                    {/*
                                    <p className="text-center text-muted">
                                        Lorem ipsum dolor sit amet, <a href="signup.html">Register</a> adipisicing elit. Quo nulla quibusdam cum doloremque incidunt nemo sunt a tenetur omnis odio.
                                    </p>
                                    */}
                                    <hr />
                                    
                                    <form>
                                        <div className="top-margin">
                                            <label>
                                                { signinFieldUsernameText }
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="top-margin">
                                            <label>
                                                { signinFieldPasswordText }
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input type="password" className="form-control" />
                                        </div>

                                        <hr />

                                        <div className="row">
                                            
                                            <div className="col-lg-8">
                                                {/*
                                                <b><a href="">Forgot password?</a></b>
                                                */}
                                            </div>
                                            
                                            <div className="col-lg-4 text-right">
                                                <button className="btn btn-action" type="submit">
                                                    { signinButtonSendText }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                        
                    </article>
                    {/* /Article */}

                </div>
            </div> {/* /container */}
        </>
    );
};

export default SignIn;