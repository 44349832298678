class TranslationService {
    static async fetchTranslations() {
        const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;
        const response = await fetch(`${apiUrl}/translation/2/${navigator.language}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    }
  }
  
  export default TranslationService;
  