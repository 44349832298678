import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaDribbble, FaGithub, FaFacebook, FaWhatsapp } from 'react-icons/fa';

const Footer = ({ translations }) => {

    const navbarTranslations = translations?.components?.navbar?.translations || {};
    const homeTitleText = navbarTranslations['menu.title.home'];
    const aboutTitleText = navbarTranslations['menu.title.about'];
    const contactTitleText = navbarTranslations['menu.title.contact'];
    const signInTitleText = navbarTranslations['menu.title.signin'];

    const contactTranslations = translations?.components?.contact?.translations || {};
    const contactEmailText = contactTranslations['contact.email.1'];
    const instagramUrlText = contactTranslations['contact.instagram.1'];
    const contactAddressBodyText = contactTranslations['contact.address.body'];
    const contactPhoneBodyText = contactTranslations['contact.phone.body'];
    const contactWhatsappUrl = `https://wa.me/34${contactPhoneBodyText}?text=Hola%20me%20interesa%20tu%20servicio`;

    const objectTranslations = translations?.components?.footer?.translations || {};
    const footerTitle1Text = objectTranslations['footer.title.1'];
    const footerTitle2Text = objectTranslations['footer.title.2'];
    const footerTitle3Text = objectTranslations['footer.title.3'];
    const footerBody3Text = objectTranslations['footer.body.3'];

    return (
        <footer id="footer" className="top-space">

            <div className="footer1">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-3 widget">
                            <h3 className="widget-title">{ footerTitle1Text }</h3>
                            <div className="widget-body">
                                <p>
                                    <a href={contactWhatsappUrl} target="_blank" rel="noopener noreferrer">
                                        <i className="fa">
                                            <FaWhatsapp size={16} /> &nbsp;
                                            (34) { contactPhoneBodyText }<br />
                                        </i>
                                    </a>
                                    <br />
                                    <a href="mailto: ${ contactEmailText }">{ contactEmailText }</a><br />
                                    <br />
                                    { contactAddressBodyText}
                                </p>    
                            </div>
                        </div>

                        <div className="col-md-3 widget">
                            <h3 className="widget-title">{ footerTitle2Text }</h3>
                            <div className="widget-body">
                                <p className="follow-me-icons">
                                    <a href={ instagramUrlText } aria-label="instsagram">
                                        <FaInstagram size={26} />
                                    </a>
                                    {/* &nbsp;
                                    <a href="https://dribbble.com" aria-label="Dribbble">
                                        <FaDribbble size={26} />
                                    </a> &nbsp;
                                    <a href="https://github.com" aria-label="GitHub">
                                        <FaGithub size={26} />
                                    </a> &nbsp;
                                    <a href="https://facebook.com" aria-label="Facebook">
                                        <FaFacebook size={26} />
                                    </a>
                                    */}
                                </p>    
                            </div>
                        </div>

                        <div className="col-md-6 widget">
                            <h3 className="widget-title">{ footerTitle3Text }</h3>
                            <div className="widget-body">
                                <p>{ footerBody3Text }</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="footer2">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-6 widget">
                            <div className="widget-body">
                                <p className="simplenav">
                                    <Link to="/">{ homeTitleText }</Link>&nbsp; | &nbsp; 
                                    <Link to="/about">{ aboutTitleText }</Link>&nbsp; | &nbsp;
                                    <Link to="/contact">{ contactTitleText }</Link>&nbsp; | &nbsp;
                                    <Link to="/signin">{ signInTitleText }</Link>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 widget">
                            <div className="widget-body">
                                <p className="text-right">
                                    Copyright &copy; 2014, <strong>San & Partners</strong>. All Rights Reserved
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </footer>
    );
};

export default Footer;
