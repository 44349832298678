import React, { useEffect, useState } from 'react';
import { MemoryRouter as Router, Route, Routes } from 'react-router-dom';

import TranslationService from './services/TranslationService';

import Navbar from './components/navbar/Navbar';
import Header from './components/header/Header';
import Intro from './components/intro/Intro';
import Highlights from './components/highlights/Highlights';
import Faq from './components/faq/Faq';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import SignIn from './components/sign-in/SignIn';
import Footer from './components/footer/Footer';

function App() {
  const [translations, setTranslations] = useState({});
  const [error, setError] = useState(null);

  // Componente contenedor para la página principal
  const HomePage = ({ translations }) => {
    const headerIsEnabled = translations?.components?.header?.enabled;
    const introIsEnabled = translations?.components?.intro?.enabled;
    const highlightsIsEnabled = translations?.components?.highlights?.enabled;
    const faqIsEnabled = translations?.components?.faq?.enabled;

    return (
      <>
        {headerIsEnabled && <Header translations={translations} />}
        {introIsEnabled && <Intro translations={translations} />}
        {highlightsIsEnabled && <Highlights translations={translations} />}
        {faqIsEnabled && <Faq translations={translations} />}
      </>
    );
  };

  // Componente contenedor para las páginas que comparten el mismo layout
  const Layout = ({ children, translations }) => {
    const navIsEnabled = translations?.components?.navbar?.enabled;
    const footerIsEnabled = translations?.components?.footer?.enabled;

    return (
      <>
        {navIsEnabled && <Navbar translations={translations} />}
        {children}
        {footerIsEnabled && <Footer translations={translations} />}
      </>
    );
  };

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const data = await TranslationService.fetchTranslations();
        setTranslations(data);
      } catch (error) {
        setError(error.message);
      } 
    };

    fetchTranslations();
  }, []);

  if (error) {
    return <div>Error loading translations: {error}</div>;
  }

  const aboutIsEnabled = translations?.components?.about?.enabled;
  const contactIsEnabled = translations?.components?.contact?.enabled;
  const signInIsEnabled = translations?.components?.signin?.enabled;
  
  return (
    <Router>

      <Routes>

        {/* Ruta principal con el layout común y contenido específico */}
        <Route
          path="/"
          element={
            <Layout translations={translations}>
              <HomePage translations={translations} />
            </Layout>
          }
        />

        {/* Ruta para la página About con el layout común */}
        <Route
          path="/about"
          element={
            <Layout translations={translations}>
              {aboutIsEnabled && <About translations={translations} />}
            </Layout>
          }
        />

        {/* Ruta para la página Contact con el layout común */}
        <Route
          path="/contact"
          element={
            <Layout translations={translations}>
              {contactIsEnabled && <Contact translations={translations} />}
            </Layout>
          }
        />

        {/* Ruta para la página SignIn con el layout común */}
        <Route
          path="/signin"
          element={
            <Layout translations={translations}>
              {signInIsEnabled && <SignIn translations={translations} />}
            </Layout>

          }
        />

      </Routes>

    </Router>
  );
}

export default App;
