import React from 'react';
import { Link } from 'react-router-dom';

const FAQ = ({ translations }) => {

    const objectTranslations = translations?.components?.faq?.translations || {};

    const faqTitleText = objectTranslations['faq.title'];

    const faqTitle1Text = objectTranslations['faq.title.1'];
    const faqBody1Text = objectTranslations['faq.body.1'];

    const faqTitle2Text = objectTranslations['faq.title.2'];
    const faqBody2Text = objectTranslations['faq.body.2'];

    const faqTitle3Text = objectTranslations['faq.title.3'];
    const faqBody3Text = objectTranslations['faq.body.3'];

    const faqTitle4Text = objectTranslations['faq.title.4'];
    const faqBody4Text = objectTranslations['faq.body.4'];

    const faqInfoText = objectTranslations['faq.info'];
    const faqButtonText = objectTranslations['faq.info.button'];
    const faqButtonUrl = objectTranslations['faq.info.button.url'];

    return (
        <div className="container">
            <h2 className="text-center top-space">{ faqTitleText }</h2>
            <br />

            <div className="row">
                <div className="col-sm-6">
                    <h3>{ faqTitle1Text }</h3>
                    <p>
                        { faqBody1Text }
                    </p>
                </div>
                <div className="col-sm-6">
                    <h3>{ faqTitle2Text }</h3>
                    <p>
                        { faqBody2Text }
                    </p>
                </div>
            </div> {/* /row */}

            <div className="row">
                <div className="col-sm-6">
                    <h3>{ faqTitle3Text }</h3>
                    <p>
                        { faqBody3Text }
                    </p>
                </div>
                <div className="col-sm-6">
                    <h3>{ faqTitle4Text }</h3>
                    <p>
                        { faqBody4Text }
                    </p>
                </div>
            </div> {/* /row */}

            <div className="jumbotron top-space">
                <h4>
                    { faqInfoText }
                </h4>
                <p className="text-right">
                    <Link className="btn btn-primary btn-large" role="button" to={ faqButtonUrl }>
                        { faqButtonText }
                    </Link>  
                </p>
            </div>
        </div> 
    );
};

export default FAQ;
