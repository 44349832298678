class ContactService {
    static async sendContactRequest(contactData) {
        const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;
        const response = await fetch(`${apiUrl}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }
}

export default ContactService;
