import React from 'react';
import { Link } from 'react-router-dom';

const About = ({ translations }) => {

    const navbarTranslations = translations?.components?.navbar?.translations || {};
    const homeTitleText = navbarTranslations['menu.title.home'];
    const aboutTitleText = navbarTranslations['menu.title.about'];

    const objectTranslations = translations?.components?.about?.translations || {};
    const aboutTitle1Text = objectTranslations['about.title.1'];
    const aboutBody1Text = objectTranslations['about.body.1'];

    const aboutTitle2Text = objectTranslations['about.title.2'];
    const aboutBody2Text = objectTranslations['about.body.2'];

    const aboutTitle3Text = objectTranslations['about.title.3'];
    const aboutBody3Text = objectTranslations['about.body.3'];

    const aboutTitle4Text = objectTranslations['about.title.4'];
    const aboutBody4Text = objectTranslations['about.body.4'];

    const aboutTitle5Text = objectTranslations['about.right.title.5'];
    const aboutSubTitle5Text = objectTranslations['about.right.subtitle.5'];
    const aboutBody5Text = objectTranslations['about.right.body.5'];
    const aboutRedirect5Url = objectTranslations['about.right.url.5'];
    
    return (
        <>
            <header id="head" className="secondary"></header>

            <div className="container">

                <ol className="breadcrumb">
                    <li><Link to="/">{ homeTitleText }</Link></li>
                    <li className="active">{ aboutTitleText }</li>
                </ol>

                <div className="row">

                    <article className="col-sm-8 maincontent">
                        <header className="page-header">
                            <h1 className="page-title">{ aboutTitleText }</h1>
                        </header>

                        <h3>{ aboutTitle1Text }</h3>
                        <p>
                            { aboutBody1Text }
                        </p>

                        <h3>{ aboutTitle2Text }</h3>
                        <p>
                            { aboutBody2Text }
                        </p>

                        <h3>{ aboutTitle3Text }</h3>
                        <p>
                        { aboutBody3Text }
                        </p>

                        <h3>{ aboutTitle4Text }</h3>
                        <p>
                        { aboutBody4Text }
                        </p>

                    </article>
            
                    <aside className="col-sm-4 sidebar sidebar-right">
                        <div className="widget">
                            <h4>{ aboutTitle5Text }</h4>
                            <ul className="list-unstyled list-spaces">
                                <li>
                                <Link to={ aboutRedirect5Url }>
                                    { aboutSubTitle5Text }
                                </Link>  
                                    
                                <br />
                                <span className="small text-muted">{ aboutBody5Text }</span></li>
                              </ul>
                        </div>
                    </aside>

                </div>
            </div> 
        </>
    );
};

export default About;
