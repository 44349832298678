import React from 'react';
import { FaHome, FaBuilding, FaHammer, FaHandHolding } from 'react-icons/fa';


const Highlights = ({ translations }) => {

    const objectTranslations = translations?.components?.highlights?.translations || {};

    const highlightsTitleText = objectTranslations['highlights.title'];
    const highlightsTitle1Text = objectTranslations['highlights.title.1'];
    const highlightsBody1Text = objectTranslations['highlights.body.1'];

    const highlightsTitle2Text = objectTranslations['highlights.title.2'];
    const highlightsBody2Text = objectTranslations['highlights.body.2'];

    const highlightsTitle3Text = objectTranslations['highlights.title.3'];
    const highlightsBody3Text = objectTranslations['highlights.body.3'];

    const highlightsTitle4Text = objectTranslations['highlights.title.4'];
    const highlightsBody4Text = objectTranslations['highlights.body.4'];

    return (
        <div className="jumbotron top-space">
            <div className="container">
                <h3 className="text-center thin">{ highlightsTitleText }</h3>
                <div className="row">
                    <div className="col-md-3 col-sm-6 highlight">
                        <div className="h-caption">
                            <h4><i className="fa fa-5"><FaHome/></i>{ highlightsTitle1Text }</h4>
                        </div>
                        <div className="h-body text-center">
                            <p>{ highlightsBody1Text }</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 highlight">
                        <div className="h-caption">
                            <h4><i className="fa fa-5">< FaBuilding /></i>{ highlightsTitle2Text }</h4>
                        </div>
                        <div className="h-body text-center">
                            <p>{ highlightsBody2Text }</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 highlight">
                        <div className="h-caption">
                            <h4><i className="fa fa-5">< FaHammer /></i>{ highlightsTitle3Text }</h4>
                        </div>
                        <div className="h-body text-center">
                            <p>{ highlightsBody3Text }</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 highlight">
                        <div className="h-caption">
                            <h4><i className="fa fa-5">< FaHandHolding /></i>{ highlightsTitle4Text }</h4>
                        </div>
                        <div className="h-body text-center">
                            <p>{ highlightsBody4Text }</p>
                        </div>
                    </div>
                </div> {/* /row  */}
            </div>
        </div>
    );
};

export default Highlights;
