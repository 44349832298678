import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import ContactService from '../../services/ContactService';
import { Link } from 'react-router-dom';

const Contact = ({ translations }) => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
  
    const navbarTranslations = translations?.components?.navbar?.translations || {};
    const homeTitleText = navbarTranslations['menu.title.home'];
    const contactTitleText = navbarTranslations['menu.title.contact'];
  
    const contactTranslations = translations?.components?.contact?.translations || {};

    const contactInfoText = contactTranslations['contact.info'];
    const contactFieldNameText = contactTranslations['contact.field.name'];
    const contactFieldEmailText = contactTranslations['contact.field.email'];
    const contactFieldPhoneText = contactTranslations['contact.field.phone'];
    const contactFieldMessageText = contactTranslations['contact.field.message'];
    const contactButtonSendText = contactTranslations['contact.button.send'];
    const contactMessageReturnSuccessText = contactTranslations['contact.message.success'];
    const contactMessageReturnErrorText = contactTranslations['contact.message.error'];

    const contactAddressTitleText = contactTranslations['contact.address.title'];
    const contactAddressBodyText = contactTranslations['contact.address.body'];

    const contactPhoneTitleText = contactTranslations['contact.phone.title'];
    const contactPhoneBodyText = contactTranslations['contact.phone.body'];

    const contactWhatsappUrl = `https://wa.me/34${contactPhoneBodyText}?text=Hola%20me%20interesa%20tu%20servicio`;

    const [formData, setFormData] = useState({
        contactName: '', 
        email: '',
        phone: '',        
        locale: navigator.language,
        message: '',
        pageId: 2,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
    
    try {
        await ContactService.sendContactRequest(formData);
        setMessage(contactMessageReturnSuccessText); 
        setFormData({
        contactName: '',
        email: '',
        phone: '',
        locale: navigator.language,
        message: '',
        pageId: 2,
        }); 
    } catch (error) {
        setError(contactMessageReturnErrorText || error.message); 
    }
    };

    return (
        <>
            <header id="head" className="secondary"></header>

            {/* container */}
            <div className="container">

                <ol className="breadcrumb">
                    <li><Link to="/">{ homeTitleText }</Link></li>
                    <li className="active">{ contactTitleText }</li>
                </ol>

                <div className="row">
                    
                    {/* Article main content */}
                    <article className="col-sm-9 maincontent">
                        <header className="page-header">
                            <h1 className="page-title">{ contactTitleText }</h1>
                        </header>
                        
                        <p>
                            { contactInfoText }
                        </p>
                        <br />
                        {message && <div className="alert alert-success">{message}</div>}
                        {error && <div className="alert alert-danger">{error}</div>}
                        <form onSubmit={handleSubmit} className="contactForm">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <input  
                                            type="text" 
                                            name="contactName"
                                            className="form-control"
                                            id="name"
                                            value={formData.contactName}
                                            onChange={handleChange}
                                            placeholder={ contactFieldNameText } />
                                        <div className="validation"></div>
                                    </div>
                                    <div className="col-sm-4">
                                        <input 
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            id="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            data-rule="email"
                                            data-msg="Please enter a valid email"
                                            placeholder={ contactFieldEmailText } />
                                        <div className="validation"></div>

                                    </div>
                                    <div className="col-sm-4">
                                        <input 
                                            type="text"
                                            name="phone"
                                            className="form-control" 
                                            id="phonr"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            placeholder={ contactFieldPhoneText } />
                                        <div className="validation"></div>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-sm-12">
                                        <textarea 
                                            name="message"
                                            className="form-control"
                                            id="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            data-rule="required"
                                            data-msg="Please write something for us"
                                            placeholder={ contactFieldMessageText }
                                            rows="9">
                                        </textarea>
                                        <div className="validation"></div>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    
                                    <div className="col-sm-6">
                                        {/*
                                        <label className="checkbox">
                                            <input type="checkbox" /> Sign up for newsletter
                                        </label>
                                        */}
                                    </div>
                                    <div className="col-sm-6 text-right">
                                        <input className="btn btn-action" type="submit" value={ contactButtonSendText } />
                                    </div>
                                </div>
                            </div>
                        </form>

                    </article>
                    {/* /Article */}
                    
                    {/* Sidebar */}
                    <aside className="col-sm-3 sidebar sidebar-right">

                        <div className="widget">
                            <h4>{ contactAddressTitleText }</h4>
                            <address>
                                { contactAddressBodyText }
                            </address>
                            <h4>{ contactPhoneTitleText }</h4>
                            <address>
                                <a href={contactWhatsappUrl} target="_blank" rel="noopener noreferrer">
                                    <i className="fa">
                                        <FaWhatsapp size={16} /> &nbsp;
                                        (34) {contactPhoneBodyText}<br />
                                    </i>
                                </a>
                            </address>
                        </div>

                    </aside>
                    {/* /Sidebar */}

                </div>
            </div> {/* /container */}
        </>
    );
};

export default Contact;
