import React from 'react';

const Intro = ({ translations }) => {

    const objectTranslations = translations?.components?.intro?.translations || {};

    const introTitleText = objectTranslations['intro.title'];
    const introBody1Text = objectTranslations['intro.body.1'];
    const introBody2Text = objectTranslations['intro.body.2'];

    return (
        <div className="container text-center">
            <br /> <br />
            <h2 className="thin">{ introTitleText }</h2>
            <p className="text-muted">
                { introBody1Text }<br /> 
                { introBody2Text }
            </p>
        </div>
    );
};

export default Intro;
